var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("ul", [
        _c(
          "li",
          [
            _c("router-link", { attrs: { to: "/exception/404" } }, [
              _vm._v(" 404 ")
            ])
          ],
          1
        ),
        _c(
          "li",
          [
            _c("router-link", { attrs: { to: "/exception/405" } }, [
              _vm._v(" 405 ")
            ])
          ],
          1
        ),
        _c(
          "li",
          [
            _c("router-link", { attrs: { to: "/exception/502" } }, [
              _vm._v(" 502 ")
            ])
          ],
          1
        )
      ]),
      _c("router-view")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }