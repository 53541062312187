<template>
  <div>
    <ul>
      <li>
        <router-link to="/exception/404"> 404 </router-link>
      </li>
      <li>
        <router-link to="/exception/405"> 405 </router-link>
      </li>
      <li>
        <router-link to="/exception/502"> 502 </router-link>
      </li>
    </ul>
    <router-view></router-view>
  </div>
  <!-- <exception-page home-route="/demo" :style="`min-height: ${minHeight}`" type="403" /> -->
</template>

<script>
// import ExceptionPage from "@/components/exception/ExceptionPage";
import { mapState } from "vuex";
export default {
  name: "Exp403",
  // components: { ExceptionPage },
  computed: {
    ...mapState("setting", ["pageMinHeight"]),
    minHeight() {
      return this.pageMinHeight ? this.pageMinHeight + "px" : "100vh";
    },
  },
};
</script>

<style scoped lang="less"></style>
